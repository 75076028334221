<template>
    <v-data-table
        :headers="headers"
        :items="entities"
        class="elevation-1"
        :footer-props="{ 'itemsPerPageOptions': [50, 100, 200, -1] }"
    >
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>{{ $t('src\\views\\bloggertrips\\list.9912') }}</v-toolbar-title>
                <v-spacer />
                <v-btn v-if="user.adminAccess && user.adminAccess.blogger_trips >= ACCESS.WRITE" color="primary" @click="create">
                    <v-icon>mdi-plus</v-icon>{{ $t('src\\views\\bloggertrips\\list.1267') }}</v-btn>
            </v-toolbar>
        </template>
        <template v-slot:[`item.updatedAt`]="{ item }">
            {{ item.updatedAt | moment('DD.MM.YYYY HH:mm') }}
        </template>
        <template v-slot:[`item.blogger`]="{ item }">
            {{ item.blogger ? `${item.blogger.social} (${item.blogger.firstname} ${item.blogger.lastname})` : '-' }}
        </template>
        <template v-slot:[`item.trip`]="{ item }">
            {{ item.trip ? item.trip.name : '-' }}
        </template>
        <template v-slot:[`item.dates`]="{ item }">
            {{ item.start | moment('DD.MM.YYYY') }} - {{ item.finish | moment('DD.MM.YYYY') }}
        </template>
        <template v-slot:[`item.price`]="{ item }">
            {{ item.price }} {{ currency }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
            {{ $t(BLOGGER_TRIP_STATUS_TITLE[item.status]) }}
            <v-menu v-if="user.adminAccess && user.adminAccess.blogger_trips >= ACCESS.WRITE">
                <template v-slot:activator="{ attrs, on }">
                    <v-icon
                        color="primary"
                        small
                        class="ml-2"
                        v-bind="attrs"
                        v-on="on"
                    >mdi-pencil</v-icon>
                </template>
                <v-list>
                    <v-list-item
                        v-for="(status, index) in BLOGGER_TRIP_STATUS_TITLE"
                        :key="index"
                        @click="setStatus(item._id, index)"
                    >
                        <v-list-item-title>{{ $t(status) }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
        <template v-slot:[`item.edit`]="{ item }">
            <v-icon color="primary" @click="edit(item._id)">mdi-pencil</v-icon>
        </template>
    </v-data-table>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { BLOGGER_TRIP_STATUS_TITLE } from '../../vars';
    import { ACCESS } from '@/vars';

    export default {
        name: 'BloggerTripsList',
        components: {
        },
        data: () => ({
            ACCESS,
            BLOGGER_TRIP_STATUS_TITLE,
            headers: [],
        }),
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('bloggerTrips', ['entities']),
            currency() {
                return process.env.VUE_APP_DEFAULT_CURRENCY
            }
        },
        async mounted() {
            await store.dispatch('bloggerTrips/fetch', {});
            this.headers = [
                { text: this.$t('src\\views\\bloggertrips\\list.updatedAt'), value: 'updatedAt' },
                { text: this.$t('src\\views\\bloggertrips\\list.blogger'), value: 'blogger' },
                { text: this.$t('src\\views\\bloggertrips\\list.trip'), value: 'trip' },
                { text: this.$t('src\\views\\bloggertrips\\list.dates'), value: 'dates' },
                { text: this.$t('src\\views\\bloggertrips\\list.price'), value: 'price' },
                { text: this.$t('src\\views\\bloggertrips\\list.status'), value: 'status' },
            ]
            if(this.user && this.user.adminAccess && this.user.adminAccess.blogger_trips >= ACCESS.WRITE) {
                this.headers.push({ text: this.$t('src\\views\\bloggertrips\\list.edit'), align: 'right', value: 'edit' })
            }
        },
        methods: {
            async create() {
                await this.$router.push({ name: 'blogger-trips-create' });
            },
            async edit(id) {
                await this.$router.push({ name: 'blogger-trips-edit', params: { id } });
            },
            async setStatus(id, status) {
                await store.dispatch('bloggerTrips/setStatus', { id, status });
                await store.dispatch('bloggerTrips/fetch', {});
            }
        }
    }
</script>
